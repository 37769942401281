import styled from "styled-components";
import MenuBar from "./components/MenuBar";
import Home from "./pages/Home";
import Skills from "./pages/Skills";
import Companies from "./pages/Companies";
import { useEffect, useState } from "react";
import LoadingIndicator from "./pages/LoadingIndicator";

const AppBody = styled.div`
  background-color: #2b2b2b;
  width: 100%;
  height: 100vh;
`;

const skills = [
  {
    title: "Technologies:",
    skills: "Kotlin, Java, Swift, Dart, Javascript, Typescript, VB.Net.",
  },
  {
    title: "Frameworks/Libraries:",
    skills:
      "Jetpack Compose, SwiftUI, React Native, React, Flutter, Hangfire, WCF.",
  },
  {
    title: "State Management:",
    skills: "ViewModel, LiveData, Flow, BLoC, Redux, Provider.",
  },
  {
    title: "Databases:",
    skills: "Sqlite, MSSQL, MySql.",
  },
  {
    title: "TDD tools:",
    skills: "JUnit, Mockito, Flutter_Test, Jest, Espresso.",
  },
  {
    title: "Task Management tools:",
    skills: "Jira, ClickUp, Trello, Smart Task, and Fresh Release.",
  },
  {
    title: "CI/CD tools:",
    skills: "CodeMagic, Github Actions.",
  },
  {
    title: "Design tool:",
    skills: "Figma.",
  },
];

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const timeOut = setTimeout(() => {
        setIsPageLoaded(true);
      }, 1000);

      return () => clearTimeout(timeOut);
    }
  }, [isLoaded]);

  return isPageLoaded ? (
    <AppBody>
      <MenuBar />
      <Home />
      <Skills items={skills} />
      <Companies />
    </AppBody>
  ) : (
    <LoadingIndicator />
  );
}

export default App;
