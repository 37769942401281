import styled from "styled-components";
import { DolapoTextProp, DolapoTextStyleProp } from "../props/interface";
import { TextAlign, TextStyle } from "../props/enums";

const Wrapper = styled.div`
  width: 100%;
`;
const DolapoTextTag = styled.p<DolapoTextStyleProp>`
  font-family: "LeagueSpartan InriaSans";
  text-align: ${({ textalign }) =>
    (textalign === TextAlign.CENTER && `center`) ||
    (textalign === TextAlign.RIGHT && `right`) ||
    (textalign === TextAlign.LEFT && `left`)};
  font-weight: ${({ fontWeight }) =>
    (fontWeight === TextStyle.NORMAL && `normal`) ||
    (fontWeight === TextStyle.SEMIBOLD && `500`) ||
    (fontWeight === TextStyle.BOLD && `bold`)};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => `${color}`};
  margin-bottom: ${({ hasmarginbottom, marginbottom }) =>
    (hasmarginbottom && `${marginbottom}px`) || `0px`};
`;
const TextUnderLine = styled.img`
  margin: auto;
  display: block;
  margin-bottom: 20px;
`;

function DolapoText(props: DolapoTextProp) {
  return (
    <Wrapper>
      <DolapoTextTag
        size={props.size}
        textalign={props.textalign}
        fontWeight={props.fontWeight}
        color={props.color}
        marginbottom={props.marginBottom}
        hasmarginbottom={props.hasMarginBottom}
      >
        {props.content}
      </DolapoTextTag>
      {props.underline && (
        <TextUnderLine src={require("../asset/image/section-indicator.png")} />
      )}
    </Wrapper>
  );
}

export default DolapoText;
