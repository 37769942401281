import styled from "styled-components";
import { SkillItem, SkillText } from "../props/interface";
import { SkillTextType } from "../props/enums";

const SkillContainer = styled.div`
  border: 1px solid #6e89bb;
  border-radius: 8px;
  padding: 18px 3px 10px 12px;
  width: 265px;
  background-color: #fff;
  margin-right: 15px;
  margin-bottom: 15px;
`;

const SkillItemTag = styled.p<SkillText>`
  font-family: "Inter";
  font-size: ${({ type }) =>
    (type === SkillTextType.TITLE && "15px") ||
    (type === SkillTextType.SKILLS && "14px")};
  color: ${({ type }) =>
    (type === SkillTextType.TITLE && `#0000004d`) ||
    (type === SkillTextType.SKILLS && `#000000`)};
`;

function Skill(item: SkillItem) {
  return (
    <SkillContainer>
      <SkillItemTag type={SkillTextType.TITLE}>{item.title}</SkillItemTag>
      <SkillItemTag type={SkillTextType.SKILLS}>{item.skills}</SkillItemTag>
    </SkillContainer>
  );
}

export default Skill;
