import styled from "styled-components";
import { CompanyLogoProps } from "../props/interface";

const CompanyWrapper = styled.div`
  margin-right: 200px;
  margin-left: 200px;
  background-color: #ededed;
  padding: 29px 250px 29px 250px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  margin-top: -50px;
  align-items: center;
  justify-content: center;
`;

const CompanyLogo = styled.img<CompanyLogoProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${({ marginright }) => `${marginright}px`};
  src: ${({ src }) => src};
`;

function Companies() {
  return (
    <CompanyWrapper>
      <CompanyLogo
        src={require("../asset/image/hts.png")}
        width={120}
        height={28}
        marginright={27}
      />
      <CompanyLogo
        src={require("../asset/image/tru.png")}
        width={74}
        height={40}
        marginright={27}
      />
      <CompanyLogo
        src={require("../asset/image/tangerine.png")}
        width={138}
        height={45}
        marginright={27}
      />
      <CompanyLogo
        src={require("../asset/image/1ubank.png")}
        width={123}
        height={49}
        marginright={0}
      />
    </CompanyWrapper>
  );
}

export default Companies;
