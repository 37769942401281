export enum SkillTextType {
    TITLE,
    SKILLS,
  }

  export enum TextStyle{
    NORMAL, SEMIBOLD, BOLD 
  }

  export enum TextAlign{
    LEFT, CENTER, RIGHT 
  }